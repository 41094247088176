.table-cover {
  width: 50px;
  height: auto;
  object-fit: cover;
  border-radius: 5px;
}


.cover-upload {
  border: 2px dashed #ccc;
  border-radius: 5px;

  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;
  height: 110px;
  cursor: pointer;
}

.block {
  display: block;
}

.hide {
  display: none;
}

.cover-preview {
  width: 100%;
  height: 100%;
  object-fit: cover;

  display: flex;
  justify-content: center;
  align-items: center;
}

.invalid-feedback {
  display: block !important;
}
//customize bootstrap
@import './bs-custom';

$strong:#646c9a;
$border: #DBDBDB;
$shadow: 0rem 0rem 2.5rem 0rem rgba(82,63,105,0.1);
$body-bg: #FFFFFF;


///////////////
// Solid Color
///////////////
$white: #fff;
$black: #000;
$charade: #23252F;

/////////////////////////
// Dark Version Color
/////////////////////////
$d-ctd: #ddd;
$d-ctl: #828690;
$d-border: #333754;
$d-bg: #20203c;
$dark-card: #292d4a;
$dark_bg_lighter: #1E2A4A;

/////////////////////////
// Primary Light
/////////////////////////
$primary-light: lighten($primary, 33%);
$secondary-light: lighten($secondary, 33%);
$success-light: lighten($success, 50%);
$warning-light: lighten($warning, 35%);
//$danger-light: lighten($danger, 26%);
$danger-light: lighten($danger, 32%);
$info-light: lighten($info, 36%);
$dark-light: lighten($dark, 50%);

/////////////////////////
// Opacity 
/////////////////////////
$primary-opacity: rgba($primary, 0.1);
$secondary-opacity: rgba($secondary, 0.5);
$success-opacity: rgba($success, 0.1);
$warning-opacity: rgba($warning, 0.1);
$danger-opacity: rgba($danger, 0.15);
$info-opacity: rgba($info, 0.1);
$dark-opacity: rgba($dark, 0.35);

////////////////////////
// Light Version Color
////////////////////////
$l-ctd: #464a53;
$l-ctl: #828690;
$l-border: #eaeaea;
$l-bg: #f2f4fa;
$heading: #333;

///////////////////
// Material Colors
///////////////////
$dusty-gray: #999999;
$gallery: #EEEEEE;
$gray: #898989;
$input-border-color: $border;


///////////////////
// Social Colors
///////////////////
$facebook: #3b5998;
$twitter: #1da1f2;
$youtube: #FF0000;
$google-plus: #db4439;
$linkedin: #007bb6;
$instagram: #c32aa3;
$pinterest: #bd081c;
$google: #4285f4;
$snapchat: #fffc00;
$whatsapp: #25d366;
$tumblr: #35465d;
$reddit: #ff4500;
$spotify: #1ed760;
$yahoo: #430297;
$dribbble: #ea4c89;
$skype: #00aff0;
$quora: #aa2200;
$riverBed: #4C585C;
$vimeo: #1ab7ea;


///////////////////
// Misc Colors
///////////////////
$iron: #DDDFE1;
$grey: #D2D6DE;
$pale-sky: #6a707e;
$athensGray: #F0F0F2;
$sky: #2290FF;





$color_pallate_1: $white;
$color_pallate_2: #F54749;
$color_pallate_3: $primary;
$color_pallate_4: #1E466A;
$color_pallate_5: #51724E;
$color_pallate_6:  #7F5B03;
$color_pallate_7: #E8AC00;
$color_pallate_8: #A1CB46;
$color_pallate_9:  #025D3A;
$color_pallate_10:  #421709;
$color_pallate_11: #c6164f;
$color_pallate_12:#343a40 ;
$color_pallate_13: #2a2a2a;
$color_pallate_14: #4885ed;
$color_pallate_15: #4cb32b;

//utility variables
$radius: 0.5rem; 
$radius-sm:0.5rem;


$loader--width: 15.625rem;
$loader-dot--size: 1.25rem;



















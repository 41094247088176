[data-sidebar-position="fixed"][data-layout="vertical"] {
    .nav-header {
        position: fixed;
        // left: auto;
        border-top-left-radius:0;
        //border-top-right-radius:1.25rem;
    }

    .dlabnav {
        position: fixed;
        // z-index: 0;
        .dlabnav-scroll {
            border-top-left-radius: 0rem;
            border-top-right-radius: 0rem;
			
			.menu-title{
				color:var(--primary);
			}
        }
		//border-bottom-left-radius: 0;
		//border-bottom-right-radius: 0;
    }

    .menu-toggle {
        .dlabnav {
            position: fixed;
        }
    }
}
[data-layout="vertical"]{
	.nav-header {
        border-top-left-radius: 0;
        border-top-right-radius: 0;
    }

    .dlabnav {
		border-bottom-left-radius: 0;
		border-bottom-right-radius: 0;
		//position: fixed;
    }
}

[data-sidebar-position="static"][data-layout="vertical"] .dlabnav .dlabnav-scroll .menu-title{
	    color: var(--primary);
    padding-top: 40px;
    padding-left: 46px;
}


[data-sidebar-style="icon-hover"][data-layout="vertical"][data-container="wide-boxed"],
[data-sidebar-style="icon-hover"][data-layout="vertical"][data-container="boxed"],
[data-header-position="fixed"][data-sidebar-position="fixed"][data-sidebar-style="icon-hover"][data-layout="vertical"][data-container="wide-boxed"],
[data-header-position="fixed"][data-sidebar-position="fixed"][data-sidebar-style="icon-hover"][data-layout="vertical"][data-container="boxed"],
[data-sidebar-style="overlay"][data-layout="vertical"][data-container="wide-boxed"],
[data-sidebar-style="overlay"][data-layout="vertical"][data-container="boxed"],
[data-header-position="fixed"][data-sidebar-position="fixed"][data-sidebar-style="overlay"][data-layout="vertical"][data-container="wide-boxed"],
[data-header-position="fixed"][data-sidebar-position="fixed"][data-sidebar-style="overlay"][data-layout="vertical"][data-container="boxed"] {
    .dlabnav {
        position: absolute;
    }
}


